import sharedVarsJSON from "./helpers/shared-variables.json";

const sharedVars = JSON.parse(JSON.stringify(sharedVarsJSON));

const layoutNames: { [key: string]: "desktop" | "mobile" } = {
  "screen-xs-min": "mobile",
  "screen-sm-min": "mobile",
  "screen-md-min": "desktop",
  "screen-lg-min": "desktop",
  "screen-xl-min": "desktop",
};

const specialCharacters = /["'=!+#*~;^()<>[\]@:?%]/g;

const isConfiguredAndLoaded = new Set<string>();

const verticalVideoYAxisFixedScaling = {
  height: 660,
  scaleFactor: 0.34375,
};

export {
  isConfiguredAndLoaded,
  specialCharacters,
  layoutNames,
  sharedVars,
  verticalVideoYAxisFixedScaling,
};
