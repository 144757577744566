import type { AstAdObj } from "@omni/ads";

const calculateScaleFactor = (adObj: AstAdObj) => {
  const adWidth = adObj.width;
  const adHeight = adObj.height;
  const scaleX = window.screen.width / adWidth;
  const scaleY = window.screen.height / adHeight;
  return { scaleX, scaleY };
};

export { calculateScaleFactor };
