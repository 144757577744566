import { useEffect, useState } from "react";

import type { EventBuilders } from "@spt-tracking/pulse-sdk";

type TCFCategories =
  | "CMP:marketing"
  | "CMP:analytics"
  | "CMP:personalisation"
  | "CMP:advertising";

type TCFPermission = "1" | "0";

interface TCF {
  getPermission: (
    category: TCFCategories,
    callback: (value: TCFPermission) => void,
  ) => void;
  subscribe: (
    category: TCFCategories,
    callback: (value: TCFPermission) => void,
  ) => () => Promise<void>;
  showPrivacyManager: () => void;
  getConsentedToAllSync: () => boolean;
  isConsentedToAll(callback: (value: boolean) => void): () => void;
  getCachedOrDefaultConsentsForPulse: () => EventBuilders.Consents;
  onTcfReady: (cb: () => void) => void;
}

function getTCF(): Promise<TCF> {
  return new Promise((resolve) => {
    setTimeout(() => {
      const cookieConsent = window._tcf_;
      if (cookieConsent) {
        resolve(cookieConsent);
      } else {
        resolve(getTCF());
      }
    }, 100);
  });
}

async function isConsentToAll() {
  const tcf = await getTCF();

  return tcf.getConsentedToAllSync();
}

function getTCFSync(cb: (sdk: TCF) => void): void {
  getTCF().then(cb);
}

function transformPermissionValue(value: string): boolean {
  return Boolean(Number(value));
}

const triggerPrivacyManager = async () => {
  const cookieConsent = await getTCF();
  return cookieConsent.showPrivacyManager();
};

function useTcfPermission(category: TCFCategories) {
  const [permission, setPermission] = useState<null | boolean>(null);

  useEffect(() => {
    getTCFSync((tcf) => {
      tcf.subscribe(category, (value: number | string) => {
        setPermission(Boolean(Number(value)));
      });
    });
  }, [category]);

  return permission;
}

const triggerTCFSettingsModal = async () => {
  const tcf = await getTCF();
  tcf.showPrivacyManager();
};

const isConsentSet = (consents: EventBuilders.Consents) =>
  Object.values(consents.purposes).every((entry) => entry.status !== "unknown");

function waitForConsent(tcfClient: TCF): Promise<EventBuilders.Consents> {
  return new Promise((resolve) => {
    const consents = tcfClient.getCachedOrDefaultConsentsForPulse();
    if (!isConsentSet(consents)) {
      setTimeout(() => {
        resolve(waitForConsent(tcfClient));
      }, 2000);
    } else {
      resolve(consents);
    }
  });
}

export type { TCFPermission, TCFCategories, TCF };
export {
  useTcfPermission,
  triggerPrivacyManager,
  transformPermissionValue,
  getTCFSync,
  isConsentToAll,
  getTCF,
  triggerTCFSettingsModal,
  waitForConsent,
};
