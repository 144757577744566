const split = (needle: string) => (str: string) => str.split(needle);
const trim = (x: string) => x.trim();

const getClientCookie = (name: string): string | null => {
  const cookies = document.cookie.split(";").map(trim).map(split("="));
  const found = cookies.find((cookie) => cookie[0] === name);

  if (found) {
    return found[1];
  }

  return null;
};

export { getClientCookie };
